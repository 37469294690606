export const assets = {
  HIGH_SVG:
    '<svg fill="none" viewBox="0 0 116 95" xmlns="http://www.w3.org/2000/svg"><mask id="test_svg__a" fill="#fff"><path d="M50 2.837h66v35H50v-35Z"/></mask><path fill="#FFC000" fill-opacity=".4" d="M50 2.837h66v35H50v-35Z"/><path fill="#FFC000" d="M50 7.837h66v-10H50v10Z" mask="url(#test_svg__a)"/><ellipse cx="19.5" cy="7.422" fill="#fff" rx="7.5" ry="7.422"/><rect width="22" height="38" x="8" y="17.002" fill="#fff" rx="2"/><path fill="#fff" d="M30.75 17.002c.134 0 .24.115.227.249A11.82 11.82 0 0 1 19.205 28H.622A.622.622 0 0 1 0 27.38V25.92a8.919 8.919 0 0 1 8.92-8.919h21.83Z"/><rect width="6" height="33" y="22.002" fill="#fff" rx="3"/><rect width="10" height="54" x="20" y="41.002" fill="#fff" rx="5"/><rect width="10" height="54" x="8" y="41.002" fill="#fff" rx="5"/><rect width="6" height="32.286" x="27.408" y="28.514" fill="#fff" rx="3" transform="rotate(-135 27.408 28.514)"/></svg>',
  MID_SVG:
    '<svg fill="none" viewBox="0 0 116 95" xmlns="http://www.w3.org/2000/svg"><mask id="test_svg__a" fill="#fff"><path d="M50 17h66v35H50V17Z"/></mask><path fill="#FFC000" fill-opacity=".4" d="M50 17h66v35H50V17Z"/><path fill="#FFC000" d="M50 22h66V12H50v10Z" mask="url(#test_svg__a)"/><ellipse cx="19.67" cy="7.5" fill="#fff" rx="7.565" ry="7.5"/><rect width="22" height="38" x="8" y="17" fill="#fff" rx="2"/><path fill="#fff" d="M30.536 17c.762 0 1.338.69 1.202 1.44A11.642 11.642 0 0 1 20.284 28H.68a.68.68 0 0 1-.68-.68v-1.4A8.919 8.919 0 0 1 8.92 17h21.616Z"/><rect width="6" height="33" y="22" fill="#fff" rx="3"/><rect width="10" height="54" x="20" y="41" fill="#fff" rx="5"/><rect width="10" height="54" x="8" y="41" fill="#fff" rx="5"/><path fill="#fff" d="M32.379 30.132a3.75 3.75 0 0 0 4.979 1.826 2.25 2.25 0 0 0 1.095-2.987l-4.748-10.249a3 3 0 1 0-5.444 2.522l4.118 8.888Z"/><rect width="6" height="19.899" x="36.232" y="33.289" fill="#fff" rx="3" transform="rotate(-134.856 36.232 33.29)"/></svg> ',
  LOW_SVG:
    '<svg fill="none" viewBox="0 0 116 95" xmlns="http://www.w3.org/2000/svg"><mask id="test_svg__a" fill="#fff"><path d="M50 47h66v35H50V47Z"/></mask><path fill="#FFC000" fill-opacity=".4" d="M50 47h66v35H50V47Z"/><path fill="#FFC000" d="M50 52h66V42H50v10Z" mask="url(#test_svg__a)"/><circle cx="19.5" cy="7.5" r="7.5" fill="#fff"/><rect width="22" height="38" x="8" y="17" fill="#fff" rx="2"/><path fill="#fff" d="M30.218 17c.42 0 .75.36.711.779A11.239 11.239 0 0 1 19.737 28H.622A.622.622 0 0 1 0 27.378v-1.46A8.919 8.919 0 0 1 8.92 17h21.298Z"/><rect width="6" height="33" y="22" fill="#fff" rx="3"/><rect width="10" height="54" x="20" y="41" fill="#fff" rx="5"/><rect width="10" height="54" x="8" y="41" fill="#fff" rx="5"/><path fill="#fff" d="M42.994 49.16a3.08 3.08 0 0 0 5.378-3.001l-13.97-26.69a4.54 4.54 0 0 0-5.708-2.11 3.405 3.405 0 0 0-1.664 4.898l15.964 26.902Z"/></svg>',
  MONK_HIGH_SVG:
    '<svg fill="none" viewBox="0 0 132 132" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#test_svg__a)"><path fill="#fff" d="M16 55.416a2.654 2.654 0 0 1 2.405-2.643l14.453-1.36a4 4 0 0 1 4.375 3.982v32.108H22.65a6.65 6.65 0 0 1-6.65-6.65V55.416ZM32.86 96.725a3.981 3.981 0 0 1 7.962 0v13.27a3.982 3.982 0 1 1-7.963 0v-13.27ZM25 109.744a4 4 0 0 1-8 0v-14a4 4 0 0 1 8 0v14Z"/><path fill="#fff" d="M28 83.634 35.514 81l4.828 13.776a3.981 3.981 0 1 1-7.514 2.634L28 83.634ZM17 87.249a6.193 6.193 0 0 1 6.193-6.193H25v14.579a4 4 0 0 1-8 0v-8.386Z"/><circle cx="22.616" cy="39.616" r="10.616" fill="#fff"/><path fill="#fff" d="M44.044 43.274a4.555 4.555 0 1 1 5.06 7.576l-13.3 8.88-1.78-2.666a5.903 5.903 0 0 1 1.63-8.188l8.39-5.602Z"/><path fill="#fff" d="M46.452 33.074a4.555 4.555 0 0 1 8.661 2.828L51.114 48.15a4.555 4.555 0 0 1-8.66-2.828l3.998-12.248Z"/><path fill="url(#test_svg__b)" d="M132 114.244v-96.5H60v12l72 84.5Z" opacity=".7"/><path stroke="#FFC000" stroke-dasharray="1 4" stroke-linecap="round" stroke-opacity=".5" stroke-width="2" d="m60 29.744 70 82m-70-94h69"/><rect width="10" height="18" x="53.5" y="14.013" fill="#FFC000" stroke="#fff" stroke-width="2" rx="2.654" transform="rotate(15 53.5 14.013)"/></g><defs><linearGradient id="test_svg__b" x1="122.504" x2="61.009" y1="81.206" y2="81.206" gradientUnits="userSpaceOnUse"><stop stop-color="#FFC000" stop-opacity="0"/><stop offset="1" stop-color="#FFC000"/></linearGradient><clipPath id="test_svg__a"><path fill="#fff" d="M0 0h132v132H0z"/></clipPath></defs></svg>',
  MONK_MID_SVG:
    '<svg fill="none" viewBox="0 0 132 132" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#test_svg__a)"><circle cx="26.616" cy="40.616" r="10.616" fill="#fff"/><path fill="#fff" d="M16 55.654A2.654 2.654 0 0 1 18.654 53h15.924a2.654 2.654 0 0 1 2.655 2.654v31.849H22.65a6.65 6.65 0 0 1-6.65-6.65V55.654Z"/><path fill="#fff" d="M46.505 64.638a4.555 4.555 0 1 1-6.727 6.145L29.65 59.695a2.447 2.447 0 0 1 .156-3.457l2.049-1.87a3.89 3.89 0 0 1 5.495.248l9.155 10.022Z"/><path fill="#fff" d="M48.971 56.943a4.555 4.555 0 0 1 5.887 6.954l-8.495 7.19a4.555 4.555 0 0 1-5.886-6.953l8.494-7.19ZM32.86 96.725a3.981 3.981 0 0 1 7.962 0v13.27a3.982 3.982 0 1 1-7.963 0v-13.27ZM25 109.744a4 4 0 0 1-8 0v-14a4 4 0 0 1 8 0v14Z"/><path fill="#fff" d="M28 83.634 35.514 81l4.828 13.776a3.981 3.981 0 1 1-7.514 2.634L28 83.634ZM17 87.249a6.193 6.193 0 0 1 6.193-6.193H25v14.579a4 4 0 0 1-8 0v-8.386Z"/><path fill="url(#test_svg__b)" d="M132 98V22L60 54v12l72 32Z" opacity=".7"/><path stroke="#FFC000" stroke-dasharray="1 4" stroke-linecap="round" stroke-opacity=".5" stroke-width="2" d="m60 54 70-31M60 66l70 31"/><rect width="10" height="18" x="51" y="51" fill="#FFC000" stroke="#fff" stroke-width="2" rx="2.654"/></g><defs><linearGradient id="test_svg__b" x1="122.504" x2="61.009" y1="71.981" y2="71.981" gradientUnits="userSpaceOnUse"><stop stop-color="#FFC000" stop-opacity="0"/><stop offset="1" stop-color="#FFC000"/></linearGradient><clipPath id="test_svg__a"><path fill="#fff" d="M0 0h132v132H0z"/></clipPath></defs></svg>',
  MONK_LOW_SVG:
    '<svg fill="none" viewBox="0 0 132 132" xmlns="http://www.w3.org/2000/svg"><circle cx="36.616" cy="55.616" r="10.616" fill="#fff"/><path fill="#fff" d="M23.076 68.544a2.654 2.654 0 0 1 3.3-1.788l15.264 4.538a2.654 2.654 0 0 1 1.788 3.3l-7.936 26.695a4 4 0 0 1-4.974 2.694l-13.974-4.154a2.654 2.654 0 0 1-1.788-3.3l8.32-27.985Z"/><path fill="#fff" d="M48.07 85.414a4.555 4.555 0 1 1-8.52 3.224L34.796 76.07a2.249 2.249 0 0 1 1.308-2.9l4.314-1.631a2.249 2.249 0 0 1 2.9 1.307l4.753 12.567Z"/><path fill="#fff" d="M55.476 85.7a4.555 4.555 0 0 1-2.298 8.816l-10.77-2.807a4.555 4.555 0 0 1 2.298-8.816l10.77 2.808ZM38 97.744a4 4 0 0 1 8 0v12a4 4 0 0 1-8 0v-12ZM25.251 105.744a3.98 3.98 0 1 1 0 7.962h-13.27a3.98 3.98 0 1 1 0-7.962h13.27Z"/><path fill="#fff" d="M35.783 104.121a6.193 6.193 0 0 1-8.101-3.327L27 99.161l13.47-5.627a3.981 3.981 0 0 1 3.068 7.347l-7.755 3.24ZM14.912 98.912a1.69 1.69 0 0 1 .618-2.307l5.433-3.136 8.194 14.192a3.98 3.98 0 1 1-6.896 3.981l-7.35-12.73Z"/><path fill="url(#test_svg__a)" d="M132 118V42L60 86v12l72 20Z" opacity=".7"/><path stroke="#FFC000" stroke-dasharray="1 4" stroke-linecap="round" stroke-opacity=".5" stroke-width="2" d="M129 44 60 86m0 12 69 19"/><rect width="10" height="18" x="51" y="83" fill="#FFC000" stroke="#fff" stroke-width="2" rx="2.654"/><defs><linearGradient id="test_svg__a" x1="122.504" x2="61.009" y1="91.981" y2="91.981" gradientUnits="userSpaceOnUse"><stop stop-color="#FFC000" stop-opacity="0"/><stop offset="1" stop-color="#FFC000"/></linearGradient></defs></svg>',
};
